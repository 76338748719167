<template>
  <b-card>
    <div v-html="data.content" />

  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      data: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initEditor()
    })
  },
  methods: {
    initEditor() {
      const data = localStorage.getItem('brandDetail')
      if (data) {
        this.data = JSON.parse(data)
      }
    },
  },
}
</script>

<style>

</style>
